// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-hitta-js": () => import("./../../src/pages/hitta.js" /* webpackChunkName: "component---src-pages-hitta-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-produkter-bernt-js": () => import("./../../src/pages/produkter/bernt.js" /* webpackChunkName: "component---src-pages-produkter-bernt-js" */),
  "component---src-pages-produkter-cykelstall-js": () => import("./../../src/pages/produkter/cykelställ.js" /* webpackChunkName: "component---src-pages-produkter-cykelstall-js" */),
  "component---src-pages-produkter-gosta-js": () => import("./../../src/pages/produkter/gösta.js" /* webpackChunkName: "component---src-pages-produkter-gosta-js" */),
  "component---src-pages-produkter-index-js": () => import("./../../src/pages/produkter/index.js" /* webpackChunkName: "component---src-pages-produkter-index-js" */),
  "component---src-pages-produkter-jenny-js": () => import("./../../src/pages/produkter/jenny.js" /* webpackChunkName: "component---src-pages-produkter-jenny-js" */),
  "component---src-pages-produkter-nicholas-js": () => import("./../../src/pages/produkter/nicholas.js" /* webpackChunkName: "component---src-pages-produkter-nicholas-js" */),
  "component---src-pages-tack-js": () => import("./../../src/pages/tack.js" /* webpackChunkName: "component---src-pages-tack-js" */)
}

